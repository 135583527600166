<template>
  <IRBaseButton
    elevation="0"
    class="donation-control-element"
    v-bind="$attrs"
    @click="openDonationLink"
  >
    <BaseSpriteIcon
      v-if="controlIcon"
      custom-class="me-2"
      :icon-name="controlIcon"
    />
    <span v-if="controlLabel">{{ controlLabel }}</span>
  </IRBaseButton>
</template>

<script>
import IRBaseButton from '@/components/base/IRBaseButton/IRBaseButton';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';

export default {
  name: 'DonationControl',
  components: {
    IRBaseButton,
    BaseSpriteIcon
  },
  props: {
    controlLabel: {
      type: String,
      default: ''
    },
    controlIcon: {
      type: String,
      default: ''
    }
  },
  computed: {
    isBlogApp() {
      return this.$store.getters['ContextStore/isBlogApp'];
    }
  },
  methods: {
    openDonationLink() {
      if (this.isBlogApp) {
        return;
      }

      const userEmail = this.$store.getters['UserStore/getUserEmail'];
      const url = this.$store.getters['ContextStore/getDonationUrl'](userEmail);
      if (!url) {
        return;
      }
      this.$store.dispatch('ContextStore/openUrl', {
        url,
        openTarget: '_blank'
      });
    }
  }
};
</script>
